<template>
  <div class="service-comment" ref="serviceComment">
    <div class="form-area" ref="formArea">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="84px"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="公司车队：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :multiple="true"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
        <el-form-item label="选择车辆：">
          <car-tree @getData="getVehIds" ref="carTree"></car-tree>
        </el-form-item>
        <!-- <el-form-item label="评价车辆：">
          <el-input v-model.trim="form.cph" placeholder="模糊搜索车牌号"></el-input>
        </el-form-item>-->
        <el-form-item label="开始时间：" prop="queryStartTime">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.queryStartTime"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="queryEndTime">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.queryEndTime"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="驾驶员姓名：">
          <el-input v-model.trim="form.driverName" placeholder="请输入驾驶员姓名"></el-input>
        </el-form-item>
        <el-form-item label="评价星级：">
          <el-select v-model="form.evaluateStatus" placeholder="请选择" clearable>
            <el-option
              v-for="item in evaluateList"
              :label="item.dictName"
              :value="item.dictValue"
              :key="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
          <el-button
        type="primary"
        size="small"
        @click="onExport"
        :loading="downLoadStatus"
        v-if="$store.state.menu.nowMenuList.indexOf('导出') >= 0"
        :disabled="tableData.length===0"
      >导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>

      <el-table-column prop="cph" label="评价车辆" :formatter="formatNull"></el-table-column>
      <el-table-column prop="driverName" label="司机姓名" :formatter="formatNull"></el-table-column>
      <el-table-column
        prop="companyName"
        label="公司"
        width="170"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column prop="operationMileage" label="行驶里程（km）">
        <template slot-scope="scope">
          <span>{{ (scope.row.operationMileage/1000).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column :formatter="formatTime" label="计价时长"></el-table-column>
      <el-table-column prop="operatingAmount" label="营收金额">
        <template slot-scope="scope">
          <span>￥{{ (scope.row.operatingAmount/100).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="outCarTime"
        label="评价时间"
        width="170"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column prop="evaluateStatus" label="评价星级" :formatter="formatNull">
        <template slot-scope="scope">
          {{ scope.row.evaluateStatus | filterEvaluateStatus }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>

import { queryCommentPagedList } from '@/api/lib/taxi-api.js'
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent } from '@/common/utils/index'

import {
  checkTimeEqualLimit,
  checkTimeLimitDay,
  checkLimitDate,
  formatDay
} from '@/common/utils/index'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import { YHTools } from '@/assets/js/Tools.js'

export default {
  name: 'serviceComment',
  components: {
    carTree,
    companySelectForSearch
  },
  data () {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }

    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeEqualLimit(value, this.form.queryEndTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (value != null && !checkTimeLimitDay(value, this.form.queryEndTime)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeEqualLimit(this.form.queryStartTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      if (
        value != null &&
        !checkTimeLimitDay(this.form.queryStartTime, value)
      ) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    return {
      downLoadStatus:false,
      tableHeight: 0,
      tableData: [],
      exportForm: {},
      form: {
        companyIdList: [],
        vehicleNoList: [],
        queryStartTime: new Date(), // 开始时间
        queryEndTime: new Date(), // 结束时间
        driverName: '',
        evaluateStatus: '',
        currentPage: 1,
        pageSize: 10
      },
      evaluateList: [
        {
          dictValue:1,
          dictName:'满意'
        },
        {
          dictValue:2,
          dictName:'一般'
        },
        {
          dictValue:3,
          dictName:'不满意'
        },
        {
          dictValue:4,
          dictName:'投诉'
        },
      ],
      total: 0,
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        queryStartTime: [
          { required: true, message: '请选择开始时间', trigger: 'blur' },

          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        queryEndTime: [
          { required: true, message: '请选择结束时间', trigger: 'blur' },

          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ]
      }
    }
  },
  filters:{
    filterEvaluateStatus(item){
      if(item==1){
        return '满意'
      }else if(item==2){
        return '一般'
      }else if(item==3){
        return '不满意'
      }
      else if(item==4){
        return '投诉'
      }else{
        return item
      }
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.serviceComment.clientHeight
      let formHeight = this.$refs.formArea.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - 32 - formHeight - paginationHeight - 10
    },
    // 获取车组数据
    getGroupIds (val) {
      // this.form.companyIds = [...val].join(',')
      this.form.companyIds = [...val]
    },
    // 获取车辆数据
    getVehIds (val) {
      // this.form.vehicleNoList = [...val].join(',')
      this.form.vehicleNoList = [...val]
    },

    /** 时间转换 */
    formatTime (row, column) {
      return YHTools.FormatTimeBySeconds(row.valuationTime) || '00:00:00'
    },
    /** 数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },

    // 提交
    onSubmit () {
      this.form.currentPage = 1
      this.getTableData()
    },
    // 获取表格数据
    getTableData () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = { ...this.form }
          data.queryStartTime = formatDay(this.form.queryStartTime)
          data.queryEndTime = formatDay(this.form.queryEndTime)
          this.exportForm = { ...data }
          queryCommentPagedList(data).then(res => {
            if (res.code === 1000) {
              this.tableData = res.data.list
              this.total = res.data.total
            } else {
              this.tableData = []
              this.total = 0
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getTableData()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getTableData()
    },
    // 下载模板
    onExport () {
      // exportEvaluateList(this.exportForm)
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "taxi",
        fileName: `服务评价统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/operateData/operateDataExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    }
  },
  created () {},
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSubmit()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.service-comment {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-taxi{
  .service-comment {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
